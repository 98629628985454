@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


@layer base{
    li {
        @apply p-4
    }

    button {
        @apply text-white border border-gray-400 
        hover:bg-slate-50/20 transition-colors rounded-sm
    }
}